import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

  

const ContentPage = styled.div`
    display:flex;
    position:relative;
    
`;
// const ContentPageWrapper = styled.div`
//     max-width:66.66666%;
//     padding:8rem 0 8rem var(--pagePadding);
//     svg {
//         max-width:100%;
//     }
    
// `;
// const ContentPageBg = styled.div`
//     display:flex;
//     position:relative;
//     top:0;
//     right:0;
//     flex-grow:1;
//     justify-content:flex-end;

//     img {
//         height:100%;
//         object-fit:cover;
//     }
    
// `;


// const ContentBlock = ({ children,title, fullWidth, children }) => (
//     <StyledContentBlock>
//       <ContentBlockHeading>{title}</ContentBlockHeading>
//       {children}
//     </StyledContentBlock>
//   );
  
//   ContentBlock.propTypes = {
//     title: PropTypes.string,
//     fullWidth: PropTypes.bool,
//     children: PropTypes.node.isRequired,
//   }
  
//   ContentBlock.defaultProps = {
//     title: '',
//     fullWidth: false,
//   }
  
//   export default ContentBlock


export default function renderContentPage({background, children}) {
    return (
    <ContentPage> 
        {children}
    </ContentPage>
    )
}