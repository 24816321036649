
import styled from "styled-components"

const ContentPageBg = styled.div`
    display:flex;
    position:relative;
    flex-grow:1;
    flex:0 0 33.333333%;
    justify-content:flex-end;
    max-height:100%;
    max-width:33.333333%;
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    z-index:-1;
    ${({theme}) => theme.media.laptop}{
        position: sticky;
        top: 70px;
        height: calc(100vh - 70px);
    }
    ${({theme}) => theme.media.tablet}{
        display:none;
    }

    .offset {
        // margin-right:5%;
    }

    img {
        height:100%;
        object-fit:cover;
    }
    video {
        height:100%;
        object-fit:cover; 
    }
    `;

export default ContentPageBg