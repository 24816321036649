import * as React from "react"
import styled from "styled-components"



const ContentPageWrapper = styled.div`
    &.centered{
      margin: 0 auto;
      padding: 4rem 0;
    }
    max-width:66.66666%;
    flex: 0 0 66.66666%;
    padding:8rem 0 8rem var(--pagePadding);
    position:relative;
    z-index:1;
    ${({theme}) => theme.media.laptop}{
        padding:3rem 0 6rem var(--pagePadding);
    }
    ${({theme}) => theme.media.tablet}{
        max-width:calc(100% - var(--pagePadding));
        flex: 0 0 calc(100% - var(--pagePadding));
        padding-top:3rem;
        padding-bottom:5rem;
    }
    .logotype {
        max-width:100%;
        ${({theme}) => theme.media.laptop}{
            max-width: 40%;
        }
        ${({theme}) => theme.media.mobile}{
            margin-bottom:-2.15rem;
        }
    }
    p {
        line-height:1.6rem;
        margin-bottom:2rem;
    }
    strong {
        &.lime {
            color:${({theme}) => theme.colors.lime};
        }
        &.magenta {
            color:${({theme}) => theme.colors.magenta};
        }
        &.blue {
            color:${({theme}) => theme.colors.blue};
        }
    }
    h2 {
        margin-top:0;
        font-size:24px;
        &.header-content {
            margin-top:.7rem;
          }
        ${({theme}) => theme.media.mobile}{
            font-size:20px;
        }
    }
`;

export default function renderContentPageWrapper({children,variant}) {
    return (
        <ContentPageWrapper className={variant}>
            {children}
        </ContentPageWrapper>
    )
}