import styled from "styled-components"

const H1 = styled.h1`
    font-size:53px;
    line-height:66px;
    font-weight:400;
    margin:2rem 0;
    ${({theme}) => theme.media.laptop}{
        font-size: 34px;
        margin: 0rem 0px 2rem 0;
        line-height: 38px;
    }
    ${({theme}) => theme.media.tablet}{
        font-size:26px;
      }
     &.subtitle {
        ${({theme}) => theme.media.mobile}{
          margin-top:0;
          margin-bottom:1rem;
          font-size:24px;
        }
      }
      a {
        transition:opacity .35s ease;
        &:hover {
          opacity:.75;
        }
      }
      
      &.h1--contact {
        ${({theme}) => theme.media.laptop}{
            margin-top: -1rem;
        }
      }
`;



export default H1 