import styled from "styled-components"

const Button = styled.button`
    position:relative;
    display:inline-flex;
    color: ${props => props.primary ? ({theme}) => theme.colors.grayDark : ({theme}) => theme.colors.white};
    font-size:1rem;
    line-height:2.55rem;
    font-weight:600;
    padding:0 1rem;
    padding-bottom:.2rem;
    background-color:transparent;
    border:1px solid;
    border-color:${props => props.primary ? ({theme}) => theme.colors.grayDark : ({theme}) => theme.colors.white};
    border-radius:4rem;
    min-width:180px;
    justify-content:center;
    align-items:center;
    transition: background-color .55s ease, color .55s ease, border-color .55s ease;
    margin:${({margin}) => margin};
    overflow:hidden;
    
    ${({theme}) => theme.media.laptop}{
        font-size: 14px;
        line-height: 2.5;
        padding: 0 1rem;

    }
    ${({theme}) => theme.media.tablet}{
        padding-bottom:0;
    }

    &::before {
        content:"";
        position:absolute;
        inset:-.5rem -3rem;
        background: linear-gradient(to right, ${({theme}) => theme.colors.lime}, ${({theme}) => theme.colors.blue}, ${({theme}) => theme.colors.magenta});
        z-index:-1;
        opacity:0;
        filter: blur(12px);
        transition: opacity .55s ease;
    }
    
    &:hover {
        
        color: ${({theme}) => theme.colors.white};
        border-color:transparent;
        &::before {
            opacity:1;
        }
    }
    &.back {
        margin-top:8rem;
        ${({theme}) => theme.media.laptop}{
            margin-top: 0;
        }
        
        ${({theme}) => theme.media.mobile}{
            margin-top:4rem;
        }
    }
`

export default Button