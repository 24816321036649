import styled from "styled-components"

const LinkList = styled.div`
    display:flex;
    position:relative;
    justify-content:flex-start;
    flex-wrap:wrap;
    gap:3rem;
    margin-bottom:1rem;
    ${({theme}) => theme.media.mobile}{
        gap:1rem;
        flex-direction:column;
    }

    a {
        text-decoration:underline;
        font-weight:600;
        transition: opacity .35s ease;
        &:hover {
            opacity:.6;
        }
    }
    `;

export default LinkList