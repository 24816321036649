import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import H1 from "../components/h1"
import Button from "../components/button"
import ContentPage from "../layouts/contentPage"
import ContentPageWrapper from "../layouts/contentPageWrapper"
import ContentPageBg from "../layouts/contentPageBg"
import LinkList from "../components/linkList";
import InstaCard from "../components/instaCard";
import Video from "../video/bubbles.mp4"

// import PrivacyPolicy from "../static/regulamin/Polityka_prywatnosci_pl.pdf";
// import CookiesPolicy from "../static/regulamin/Polityka_plikow_cookies_pl.pdf";
// import Policy from "../static/regulamin/Regulamin_pl.pdf";

const Contact = () => (
  <Layout>
    <Seo title="Kontakt" />
    <ContentPage>
      <ContentPageWrapper>
        <p>Napisz do nas:</p>
        <H1 className="h1--contact"><a href="mailto:kontakt@oxygenetic.pl">kontakt@oxygenetic.pl</a></H1>
        <InstaCard />
          <p className={"contact-text"}>Więcej informacji o przetwarzaniu danych osobowych przez Administratora dostępnych jest w <a href="/polityka-prywatnosci" target="_blank">Polityce prywatności.</a></p>
        <p>Wszystkie produkty Oxygenetic są dostępne w drogeriach Hebe, Ziko Dermo, Gemini oraz DOZ.</p>
          <Button as={Link} to="/" primary="true" margin="4rem 0 0 0">Strona główna</Button>
      </ContentPageWrapper>
      <ContentPageBg>
          <video autoPlay muted loop>
          <source src={Video} type="video/mp4" />
          </video>
          {/* <StaticImage
              src="../images/media/contact/contact-bg.png"
              loading="eager"
              width={440}
              quality={95}
              placeholder="blurred"
              className="offset"
              formats={["auto", "webp", "avif"]}
              alt="oxygenetic"
          /> */}
      </ContentPageBg>
    </ContentPage> 
  </Layout>
)

export default Contact
